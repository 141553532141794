<template>
<div class="dialog">
  <el-dialog class="dialog-main" :append-to-body="true" title="请留下联系方式，我们会第一时间联系您" :visible.sync="dialogFormVisible">
    <el-form :model="contractForm" ref="form" :rules="rules" label-width="12%">
      <el-form-item label="姓名：" prop="name">
        <el-input placeholder="请输入您的姓名" v-model="contractForm.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="电话：" prop="phone">
        <el-input type="tel" placeholder="请输入您的电话" v-model="contractForm.phone" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="邮箱："  prop="email">
        <el-input type="email" placeholder="请输入您的邮箱" v-model="contractForm.email" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="地址：" prop="address">
        <el-input placeholder="请输入您的地址" v-model="contractForm.address" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="咨询内容：" prop="text">
        <el-input placeholder="请输入您要咨询的内容" type="textarea" v-model="contractForm.text" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="onSubmit" :loading="submitLoading">提 交</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import axios from "axios";

export default {
  name: "index",
  data() {
	  var checkPhone = (rule, value, callback) => {
		  if (!value) {
			  return callback(new Error('请输入您的手机号码'));
		  }
		  let isPhone = /^1[3-9]\d{9}$/.test(value)
		  if (isPhone){
			  callback();
		  }else{
			  callback(new Error('请输入正确的手机号码'));
		  }
	  };
	  /*var checkEmail = (rule, value, callback) => {
		  if (!value) {
			  return callback(new Error('请输入您的邮箱地址'));
		  }
		  let isEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(value)
		  if (isEmail){
			  callback();
		  }else{
			  callback(new Error('请输入正确的邮箱地址'));
		  }
	  };*/
    return {
      dialogFormVisible: false,
      contractForm: {},
		rules: {
			name: [
				{ required: true, message: '请输入您的姓名', trigger: 'blur' },
			],
			phone: [
				{ required: true, validator: checkPhone, trigger: 'blur' },
			],
			text: [
				{ required: true, message: '请输入您要咨询的内容', trigger: 'blur' },
			],
		},
		submitLoading: false,
    }
  },
  methods: {
    visibleOpen() {
      this.dialogFormVisible = true
    },
	  onSubmit() {
		  var that = this
		  this.$refs['form'].validate((valid)=>{
			  if (valid){
				  this.submitLoading = true;
				  axios({
					  method: 'post',
					  url: 'https://formspree.io/f/xleqrvgw',
					  data: this.contractForm,
				  }).then(response => {
					  console.log(response);
					  that.$notify({
						  title: '成功',
						  message: '邮件已发送',
						  type: 'success'
					  });
				  }, error => {
					  that.$notify({
						  title: '失败',
						  message: '邮件发送失败',
						  type: 'error'
					  })
					  console.log('错误', error.message)
				  }).finally(()=>{
					  this.$refs['form'].resetFields();
					  this.submitLoading = false;
					  this.dialogFormVisible = false
				  });
			  }else{
				  return false;
			  }
		  })
	  }
  }
}
</script>

<style lang="scss" scoped>
/*.dialog {
  //text-align: center;
  !*.dialog-main {
    margin-left: 25%;
    //width: 1000px;
  }*!
}*/
</style>