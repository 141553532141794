import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    titleName: "first"
  },
  mutations: {
    setTitleName (state, name) {
      state.titleName = name
    }
  },
  actions: {
    setTitleName ({ commit }, name) {
      commit('setTitleName', name)
    }
  },
  getters: {
    titleName: state => {
      return state.titleName
    }
  },
  modules: {

  },

});
