<template>
	<div class="tab">
		<!--    <div class="logo" @click="toRoute('home')">-->
		<img class="logo-img" @click="toRoute('home')" :src="logo"/>
		<!--    </div>-->
		<div class="menu">
			<div class="home-page"
				 @click="toRoute('home')"
				 v-on:mouseenter="tabOnmouseover('home')"
				 v-on:mouseleave="tabOnmouseout('home')"
				 :class="{active: active==='home'}">首页
			</div>
			<div class="home-page"
				 v-on:mouseenter="tabOnmouseover('product')"
				 v-on:mouseleave="tabOnmouseout('product')"
				 :class="{active: active==='product'||active==='list'||active==='eBackup'||active==='eMoveList'||active==='eMigrate'||active==='eMove'||active==='eManage'||historyList||historyListItem}">
				<div>产品服务</div>
				<div class="product-list">
					<div class="product-triangle"
						 v-if="active == 'product' || historyList || historyListItem">
						<div class="triangle"></div>
					</div>
					<div
						v-if="active == 'product' || historyList || historyListItem"
						class="list"
						v-on:mouseenter="tabOnmouseover('list')"
						v-on:mouseleave="tabOnmouseout('list')"
					>
						<div
							@click="toRoute('eBackup')"
							class="eBackup"
							:class="{listActive: active==='eBackup'}"
							v-on:mouseenter="tabOnmouseover('eBackup')"
							v-on:mouseleave="tabOnmouseout('eBackup')"
						>
							<img class="list-icon" v-if="active == 'eBackup'" :src="eBackupListActive">
							<img class="list-icon" v-else :src="eBackupList">
							云融云备LinkedStor Backup
						</div>
						<div class="row-divider"></div>
						<div
							@click="toRoute('eMigrate')"
							class="eMove"
							:class="{listActive: active==='eMigrate'}"
							v-on:mouseenter="tabOnmouseover('eMigrate')"
							v-on:mouseleave="tabOnmouseout('eMigrate')"
						>
							<img class="list-icon" v-if="active == 'eMigrate'" :src="eMoveListActive">
							<img class="list-icon" v-else :src="eMoveList">
							数联整机云迁移LinkedStor CMove
							<!--              <div class="product-list-item"
											   v-if="active == 'eMoveList' || historyListItem"
											   v-on:mouseenter="tabOnmouseover('listItem')"
											   v-on:mouseleave="tabOnmouseout('listItem')"
										  >-->
							<!--                <div class="product-row-triangle">
											  <div class="row-triangle"></div>
											</div>-->
							<!--                <div class="list-item" >-->
							<!--                  <div
												  @click="toRoute('eMigrate')"
												  class="eMigrate-solution"
												  :class="{listActive: active==='eMigrate'}"
												  v-on:mouseenter="tabOnmouseover('eMigrate')"
												  v-on:mouseleave="tabOnmouseout('eMigrate')"
											  >
												e键主机迁移解决方案
											  </div>-->
<!--							<div
								@click="toRoute('eMove')"
								class="eMoveTool"
								:class="{listActive: active==='eMove'}"
								v-on:mouseenter="tabOnmouseover('eMove')"
								v-on:mouseleave="tabOnmouseout('eMove')"
							>
								e键搬家工具
							</div>-->
							<!--</div>-->
							<!--</div>-->
						</div>
						<div class="row-divider"></div>
						<div
							@click="toRoute('eMove')"
							class="eMoveTool"
							:class="{listActive: active==='eMove'}"
							v-on:mouseenter="tabOnmouseover('eMove')"
							v-on:mouseleave="tabOnmouseout('eMove')"
						>
							<img class="list-icon" v-if="active == 'eMove'" :src="eMoveListActive">
							<img class="list-icon" v-else :src="eMoveList">
							数联e键搬家工具LinkedStor ALLMove
						</div>
						<div class="row-divider"></div>
						<div
							@click="toRoute('eManage')"
							class="eManage"
							:class="{listActive: active==='eManage'}"
							v-on:mouseenter="tabOnmouseover('eManage')"
							v-on:mouseleave="tabOnmouseout('eManage')"
						>
							<img class="list-icon" v-if="active == 'eManage'" :src="eManageListActive">
							<img class="list-icon" v-else :src="eManageList">
							数联云桌面系统LinkedStor Yundy
						</div>
					</div>
				</div>

			</div>
			<div class="home-page"
				 @click="toRoute('customer')"
				 v-on:mouseenter="tabOnmouseover('customer')"
				 v-on:mouseleave="tabOnmouseout('customer')"
				 :class="{active: active==='customer'}">客户中心
			</div>
			<div class="home-page"
				 @click="toRoute('about')"
				 v-on:mouseenter="tabOnmouseover('about')"
				 v-on:mouseleave="tabOnmouseout('about')"
				 :class="{active: active==='about'}">关于我们
			</div>
			<div class="search_wrap">
				<!--			<div class="divider_wrap" v-if="!isShowInput">-->
				<!--				<div class="divider">-->
				<!--				</div>-->
				<!--			</div>-->
				<!--			<div  class="search">-->
				<img v-if="!isShowInput" @mouseenter="()=>isShowInput = !isShowInput" class="search-img" :src="search"/>
				<!--			</div>-->
				<el-autocomplete
					@blur="blurSearch"
					v-else
					v-model="state2"
					:fetch-suggestions="querySearch"
					placeholder="请输入内容"
					:trigger-on-focus="false"
					@select="handleSelect"
					suffix-icon="el-icon-search"
				></el-autocomplete>
			</div>
			<!--      <div>-->
			<el-button class="button-main" @click="contract">申请体验</el-button>
			<contract-form ref="contractForm"></contract-form>
			<!--      </div>-->
		</div>
	</div>
</template>

<script>
import contractForm from '../../../components/contract-form/index'

export default {
	name: "tab",
	components: {contractForm},
	data() {
		return {
			isShowInput: false,
			carouselHeight: window.screen.width * 11 / 32 + 'px',
			logo: require("../../../assets/top/logo.png"),
			search: require("../../../assets/top/search.png"),
			eBackupList: require("../../../assets/top/eBackup-list.png"),
			eBackupListActive: require("../../../assets/top/eBackup-list-active.png"),
			eManageList: require("../../../assets/top/eManage-list.png"),
			eManageListActive: require("../../../assets/top/eManage-list-active.png"),
			eMoveList: require("../../../assets/top/eMove-list.png"),
			eMoveListActive: require("../../../assets/top/eMove-list-active.png"),
			active: "",
			historyList: false,
			historyListItem: false,
			searchData: [],
			restaurants: [],
			state2: '',
		}
	},
	// 监听,当路由发生变化的时候执行
	watch: {
		$route(to) {
			this.active = to.name
		}
	},
	methods: {
		blurSearch() {
			if (this.state2 === '') {
				this.isShowInput = false;
			}
		},
		querySearch(queryString, cb) {
			// var restaurants = this.restaurants;
			var searchData = this.searchData;
			var results = queryString ? searchData.filter(this.createFilter(queryString)) : searchData;
			if (results)
				if (results.length === 0) {
					results = [{value: '暂无搜索结果'}]
				}
			// 调用 callback 返回建议列表的数据
			cb(results);
		},
		createFilter(queryString) {
			return (searchData) => {
				return (searchData.keywords.includes(queryString));
			};
		},
		loadAll() {
			return [
				{
					keywords: "首页数源助力企业数字化建设一个综合集成服务平台全面了解企业信息生命线在医疗教育政府能源企业运营商等多场景提供高效可靠的数字化解决方案帮助企业保护和管理其宝贵的数据资产数智管理一体化业务场景全覆盖运维效率倍增长企业成本急降低技术推动发展引领数智时代三大系列提供一站式解决方案信息智能备数据一键迁管理智能化利用自主研发的数据保护技术打造e键备数据服务品牌构建备份容灾存储三大功能体系最大程度提高数据安全系数的同时实现存储瘦身让数据从保值到增值基于科技时代数据需要高效利用e键搬系列产品打破传统致力于解决混合云环境与异地载体的一键迁移测试恢复难题实现数据瞬间迁移轻松上云灵活流转选用领先的终端设备支持多种终端登录访问即装即用为用户搭建搭建集中管理统一便捷智能办公学习环境为全国用户构建高效的IT轻资产保护体系丰富的行业实践企业资质",
					value: "首页介绍",
					route: "home",
				},
				{
					keywords: "企业数据备份保护解决方案痛点挑战成本投入高传统灾备投入大、成本高，前期投入耗费大量人力物力运维管控难统筹硬件软件设备环境维护难度大无法得到及时快速响应容错风险高意外发生备份数据恢复重建导致业务停机耗时长简介架构图专为企业提供数据保护和恢复功能的解决方案它通过备份关键数据并存储在安全的位置以防止数据丢失和泄露安全可靠运行客户端的业务系统将会对业务系统进行资源状态检测并将检测数据上报到控制系统，用户可在控制系统上查询业务系统的性能状态灵活管理以企业为单位创建账户可设置租户管理员和下属用户管理员根据用户部门两个维度进行容量分配和权限控制符合企业的个性化定制需求的同时也保证每个账号对任务操作权限的安全可视化管理通过直观的图形界面完成各种设置和操作提供了一站式的管理平台用户可以在同一个界面上完成多个任务如数据备份恢复监控等提供状态实时监控支持日志管理和告警信息提供多种报警方式本地数据协同传统行业中小企业客户本地数据存量小对上云无急切需求无专业运维人员云端数据协同有多区域业务的公司等多个地市开展业务文件数据库操作系统等在各云平台都有业务数据本地和云端数据协同客户数据存量大本地备份成本过高上云需求急切",
					value: "e键备介绍",
					route: "eBackup",
				},
				{
					keywords: "产品云归档盒Cfile是专门为解决公有云S3对象存储协议与现有应用程序不兼容的问题而设计的企业可以将本地数据归档和备份到S3对象存储同时保留对本地文件系统的访问方式这样企业可以利用S3对象存储的海量存储空间和低成本优势而无需对现有应用程序进行大规模的修改为企业提供了一种平滑且高效的方式来进行公有云的迁移实现了对S3对象存储的灵活应用功能存储瘦身低成本实现高效存储访问低存储量融合缓存享受高性能数据读写体验利用融合缓存技术将数据进行本地和云端的分层存储在大大降低本地存储空间的同时实现数据的快速检索定位拥有云端同本地盘一样的读写体验不受网络的影响智能缓存策略自动实现数据本地和云端的分层空间策略正常阈值范围内数据不删除超出正常阈值时发热度策略超出警戒阈值时数据强制搬迁上云热度策略警戒阈值内的数据根据数据块的访问频度进行迁移迁移完成后从缓存空间中删除保证缓存容量时间策略支持设置数据多久无访问后自动向云端转存支持秒级分钟级的时间策略设置应用无需改造云与本地无缝融合切换无需修改架构和应用代码开箱即用无需投入巨资建设异地物理备份机房利旧原有设备兼容本地存储服务协议无缝对接云端轻松实现能力升级享受本地高性能读写体验和云端海量存储空间",
					value: "e键备产品",
					route: "eBackupCFile",
				},
				{
					keywords: "案例客户需求自动化运维审计及安全管控难无法实现数据统一化备份存在数据中心整体故障影响前端业务的风险数据安全性差通用操作系统无法提供如数据快照等高级数据保护方式医院环境规模大关联复杂难以定期专业的演练及规划预案传统备份软件过分依赖主机及网络且医院数据量庞大备份和恢复时间消耗长部署方案私有化部署云融方案实现跨云平台备份实现统一界面管理实时备份IO级颗粒度完全消除备份时间窗口多种备份等级自定义全面的应用环境支持先级的数据块级备份技术面向复杂环境的数据块级的高速备份技术对备份难点的数据库及小文件的系统也能从容解决方案效益医院的备份数据得到全面的安全保护降低数据丢失损坏或被未经授权的访问的风险提供高可靠性和可用性确保备份数据在发生硬件故障自然灾害或其他意外情况时能够快速恢复保障医院业务的连续性提供自动化的备份和恢复功能减少了手动操作的工作量简化了备份管理流程使医院能够更高效地管理备份数据",
					value: "e键备案例",
					route: "eBackupCase",
				},
				{
					keywords: "大规模数据迁移需要大量时间迁移效率低导致业务中断或延迟停机迁移影响日常运行异构存储不同系统应用程序使用不同数据格式及结构无法兼容造成数据孤岛数据不一致迁移过程造成损坏丢失或者不完整导致数据可用性及准确性受到影响专为用户打造智能在线迁移的解决方案帮助用户轻松快迁移业务连续不停机实现业务持续传输上云批量启动目标云端平台无需提前一对一启动和配置实例异构适配异构平台驱动自动智能转换适配，无需人为介入操作云迁移将数据从本地数据中心迁移到云平台以获得更高的可扩展性灵活性和成本效益企业更换或合并数据中心时数据迁移是必不可少的涉及将数据从一个数据中心转移到另一个数据中心以确保业务的连续性和数据的完整性企业更换数据库系统升级数据库版本或将数据从一个数据库平台迁移到另一个数据库平台时这可以是在企业内部进行的也可以是在云平台上进行的数据库迁移应用程序迁移企业决定更换或升级应用程序时它涉及将应用程序所使用的数据从旧应用程序迁移到新应用程序以确保数据的连续性和一致性",
					value: "e键主机迁移解决方案",
					route: "eMigrate",
				},
				{
					keywords: "e键搬工具是一款专业的云电脑迁移工具主要为云电脑用户提供将本地环境一键搬家上云的能力含括文件搬家用户搬家及多款应用搬家无论是个人用户还是企业用户都可以通过该软件实现一键迁移享受云端的便利性和灵活性一键登录支持账户登录或验证码方式登录端对端确认后连接保障用户安全性自动适配软件适配主流windows桌面版和服务器版操作系统确保迁移过程的兼容性支持迁移过程断点续传保障搬家稳定性数据安全在迁移过程中软件会对用户自定义认证加密确保数据的安全性和隐私性迁移监控软件提供实时监控和反馈功能用户可以随时了解迁移的进度和状态通过e键搬工具用户可以快速将电脑应用迁移到新电脑节省了传统迁移过程中繁琐的配置和安装时间提高了工作效率支持主流操作系统数据库及存储系统轻松实现升级与扩容实现云端迁移及存储只需支付工具的使用费用实现了成本的节约通过加密和保护用户的数据确保了数据的安全性防止数据丢失和泄露的风险当企业需要升级其现有的IT系统时可以帮助将现有的应用程序和数据迁移到云端从而实现系统的升级和迁移当企业需要将其数据中心迁移到云端或者不同的数据中心时可以帮助将应用程序和数据无缝地迁移到新的环境中减少迁移过程中的停机时间和数据丢失风险e键搬工具可以帮助开发人员和测试人员快速部署和迁移应用程序到云端环境，提高开发和测试的效率同时降低环境配置和管理的复杂性支持价格规格年包月包",
					value: "e键搬家工具",
					route: "eMove",
				},
				{
					keywords: "信息安全风险高数据本地存储易泄露外设和应用难以管控办公效率低桌面上下线慢故障率高IT运维复杂运营成本高电力人力成本高替换周期频繁云计算技术引领桌面进入后PC时代将桌面操作系统应用软件和数据全部迁移到云数据中心根本上解决传统PC桌面面临的困境先进的分布式高可用融合架构从企业IT投资成本考量云玓云桌面系统LinkedStor Yundy人工智能桌面在同一套单元设备中不仅仅具备计算网络存储和服务器虚拟化等资源和技术而且还包括缓存加速重复数据删除在线数据压缩备份软件快照技术等元素而多节点可以通过网络聚合起来实现模块化的无缝横向扩展行业领先的硬件利用率在不影响用户体验的基础下相同服务器硬件指标参数领步人工智能桌面比常规设置能多部署20%以上云桌面简洁便捷的操作使用异构平台管理界面及相关设置均以满足用户使用习惯的角度来设计用户培训成本低上手快驱动自动智能转换适配无需人为介入操作创新的桌面访问方式和体验从企业安全性考量提供多种登录访问数据交换的认证的方式包括软件平台认证插卡认证生物识别认证及其的组合方式认证集中管控全面保护数据安全高效运维提升IT控制力和效率随身桌面高效工作切换便捷成本控制5年综合使用成本比传统方式节约60%",
					value: "e键管介绍",
					route: "eManage",
				},
				{
					keywords: "线上支持线下支持客户中心",
					value: "客户中心",
					route: "customer",
				},
				{
					keywords: "关于我们广州数源畅联科技有限公司Linkstor是一家专注于数据领域与科技服务的高新技术企业数源畅联自2017年创立以来致力于推进数字化技术的创新服务围绕数据安全连接安全智能服务物联网四大产品方向形成容灾备份迁移网络安全连接私有云建设物联网等产品及专业服务服务范围涵盖运营商医疗教育政务等多领域并获得了市场的一致认可数源畅联一直坚持创新突破的中心理念将技术研发和人才培养作为发展目标在公司本部设立了专门的研发部及技术部门且在上海四川武汉建立了长期稳定的合作研发团队以自主研发创新的产品和技术服务为客户提供数据领域要素的解决方案帮助客户从容应对数字化转型的业务挑战未来数源畅联仍秉持不断创新驱动变革的发展理念以技术引领发展为客户提供专业高效优质的服务发展历程荣誉资质软著认证证书许可证",
					value: "关于我们",
					route: "about",
				},
			];
		},
		// 点击选中建议项时触发
		handleSelect(item) {
			// 点击的时候跳转路由
			this.toRoute(item.route)
		},
		contract() {
			this.$nextTick(() => {
				this.$refs.contractForm.visibleOpen()
			})
		},
		tabOnmouseover(name) {
			if (name == 'list') {
				this.historyList = true
			}
			if (name == 'listItem') {
				this.historyListItem = true
			}
			this.active = name
		},
		tabOnmouseout(name) {
			if (name == 'list') {
				this.historyList = false
			}
			if (name == 'listItem') {
				this.historyListItem = false
			}
			const that = this;
			setTimeout(function () {
				if (name != that.active) {
					return
				}
				that.active = that.$route.name
			}, 400);
		},
		toRoute(name) {
			if (name == "") {
				this.$router.push({name: "home"})
			}
			this.$router.push({name})
		},
	},
	mounted() {
		this.restaurants = this.loadAll();
		this.searchData = this.loadAll();
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-icon-search:before {
	font-size: 20px;
}

::v-deep .el-autocomplete {
	width: 180px;
}

::v-deep .el-button {
	background: linear-gradient(154.73deg, rgba(13, 126, 191, 1) 0%, rgba(112, 168, 64, 1) 100%);
	color: rgba(255, 255, 255, 1);
	width: 101px;
	height: 40px;
	font-size: 16px;
	font-weight: 600;
	border: 0px solid #DCDFE6;
}

.active {
	background: linear-gradient(154.73deg, rgba(13, 126, 191, 1) 0%, rgba(112, 168, 64, 1) 100%);
}

.listActive {
	color: rgba(13, 126, 190, 1) !important;
}


.tab {
	display: flex;
	position: absolute;
	justify-content: space-between;
	top: 38px;
	left: 260px;
	width: 1400px;
	height: 49px;
	z-index: 999;

	.logo-img {
		width: 213px;
		height: 49px;
		cursor: pointer;
	}

	//.logo {
	//  width: 213px;
	//  height: 49px;
	//  cursor: pointer;
	//
	//}

	.menu {
		width: 1000px;
		display: flex;
		//margin-left: 260px;
		margin-left: 360px;
		justify-content: space-around;
		align-items: center;

		.home-page {
			//display: inline-block;
			//margin-right: 74px;
			cursor: pointer;
			border-radius: 4px;
			padding: 0 20px;
			/** 文本1 */
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 0px;
			line-height: 49px;
			color: rgba(255, 255, 255, 1);
			text-align: center;
			vertical-align: center;
			position: relative;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}

		.triangle {

			width: 0;
			height: 0;
			border-bottom: 6px solid white;
			/*border-bottom: 10px solid blue;*/
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
		}

		.row-triangle {

			width: 0;
			height: 0;
			border-right: 6px solid white;
			/*border-bottom: 10px solid blue;*/
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
		}

		.list {
			z-index: 800;
			//width: 250px;
			//height: 150px;
			overflow: hidden;
			border-radius: 3px;
			background-color: #fff;
			box-shadow: 0 1px 5px #aaa;
			transition: all .3s;
			color: black;

			.eBackup {
				width: 335px;
				margin: 3% 0;
				padding-left: 10px;
				cursor: pointer;
				/** 文本1 */
				font-size: 18px;
				font-weight: 300;
				letter-spacing: 0px;
				line-height: 26.06px;
				color: rgba(51, 51, 51, 1);
				text-align: left;
			}

			.eMove {
				width: 335px;
				margin: 3% 0;
				padding-left: 10px;
				cursor: pointer;
				/** 文本1 */
				font-size: 18px;
				font-weight: 300;
				letter-spacing: 0px;
				line-height: 26.06px;
				color: rgba(51, 51, 51, 1);
				text-align: left;
				//position: relative;
			}
			.eMoveTool{
				width: 335px;
				margin: 3% 0;
				padding-left: 10px;
				cursor: pointer;
				/** 文本1 */
				font-size: 18px;
				font-weight: 300;
				letter-spacing: 0px;
				line-height: 26.06px;
				color: rgba(51, 51, 51, 1);
				text-align: left;
			}
			.eManage {
				width: 335px;
				margin: 3% 0;
				padding-left: 10px;
				cursor: pointer;
				/** 文本1 */
				font-size: 18px;
				font-weight: 300;
				letter-spacing: 0px;
				line-height: 26.06px;
				color: rgba(51, 51, 51, 1);
				text-align: left;
			}

			.row-divider {
				margin-top: 6px;
				margin-left: 10px;
				//width: 110px;
				height: 1px;
				opacity: 0.1;
				background: rgba(0, 0, 0, 1);
			}
		}

		.list-item {
			z-index: 800;
			overflow: hidden;
			background-color: #fff;
			box-shadow: 0 1px 5px #aaa;
			transition: all .3s;
			width: 169px;
			height: 85px;
			border-radius: 3px;
			color: black;


			.eMigrate-solution {
				margin-top: 10%;
				margin-left: 10%;
				cursor: pointer;
				/** 文本1 */
				font-size: 14px;
				font-weight: 300;
				letter-spacing: 0px;
				line-height: 20.27px;
				color: rgba(102, 102, 102, 1);
				text-align: left;

			}

			.eMoveTool {
				margin-top: 5%;
				margin-left: 10%;
				cursor: pointer;
				/** 文本1 */
				font-size: 14px;
				font-weight: 300;
				letter-spacing: 0px;
				line-height: 20.27px;
				color: rgba(102, 102, 102, 1);
				text-align: left;
			}
		}

		/*	.divider_wrap{
				float: left;
				width: 1px;
				height: 20px;
				display: flex;
				align-items: center;
				margin-right: 20px;
				.divider {
					width: 1px;
					height: 20px;
					opacity: 0.21;
					background: rgba(255, 255, 255, 1);
				}
			}*/

		.search_wrap {
			width: 181px;
			display: flex;
			justify-content: flex-end;
		}

		//.search {
		//margin-top: -2px;
		//margin-right: 8px;
		//  //width: 17px;
		//  display: inline-block;
		//  //margin-right: 30px;
		//  cursor: pointer;
		//}
		.search-img {
			width: 17px;
			height: 17px;
			margin-top: -2px;
			margin-right: 8px;
			//width: 17px;
			display: inline-block;
			//margin-right: 30px;
			cursor: pointer;
		}

		//.inline-input {
		//  width: 180px;
		//}
		.button-main {
			width: 101px;
			height: 40px;
		}
	}
}

.product-list {
	position: absolute;
	top: 100%;
	//width: 100%;
}

.product-triangle {
	display: flex;
	justify-content: center;
	width: 100%;
}

.product-list-item {
	position: absolute;
	top: 47%;
	left: 210%;
	display: flex;
	justify-content: center;
	transform: translate(-50%, -50%);
}

.product-row-triangle {
	display: flex;
	align-items: center;
	height: 85px;
}

.list-icon {
	width: 23px;
	height: 23px;
}
</style>